import { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'

import history from './history'
import { store } from './redux/store'

import 'react-loading-skeleton/dist/skeleton.css'

import PageLoader from './components/UI/PageLoader/PageLoader'

const LayoutLazy = lazy(() => import('./components/Containers/Layout/Layout'))
const AuthLayoutLazy = lazy(
  () => import('./components/Containers/AuthLayout/AuthLayout')
)

const LoginLazy = lazy(() => import('./components/Pages/Login/Login'))
const NotFoundLazy = lazy(() => import('./components/Pages/NotFound/NotFound'))
const DashboardLazy = lazy(
  () => import('./components/Pages/Dashboard/Dashboard')
)
const SettingsLazy = lazy(() => import('./components/Pages/Settings/Settings'))
const DevicesLazy = lazy(() => import('./components/Pages/Devices/Devices'))

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<PageLoader />}>
          <Routes>
            <Route element={<LayoutLazy />}>
              <Route path='/' element={<LoginLazy />} />
              <Route element={<AuthLayoutLazy />}>
                <Route path='/dashboard' element={<DashboardLazy />} />
                <Route path='/devices' element={<DevicesLazy />} />
                <Route path='/settings' element={<SettingsLazy />} />
              </Route>
              <Route path='*' element={<NotFoundLazy history={history} />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Provider>
  )
}

export default App
