import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyCkKYGUtkqAAZ-z_dnexHD8iSQwzDC7EOA',
  authDomain: 'stlt-io.firebaseapp.com',
  projectId: 'stlt-io',
  storageBucket: 'stlt-io.appspot.com',
  messagingSenderId: '236204034604',
  appId: '1:236204034604:web:ea38f0cd2c61eec53d9f89',
  measurementId: 'G-EG7TPLVC8L'
}

export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const auth = getAuth(app)
