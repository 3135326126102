import { put } from 'redux-saga/effects'
import axios from '../../axios-conf'
import uris from '../../uris'
import {
  licenseLoadSuccess,
  licenseLoadFailure,
  addNotification,
  licenseSaveSuccess,
  licenseSaveFailure
} from '../actions'
import { uiHelper } from '../../helpers'
import { uiConstants } from '../../constants'

export function* licenseLoadSaga(): Generator<any, any, any> {
  try {
    const { data }: any = yield axios.get(uris.license)
    yield put(licenseLoadSuccess(data))
  } catch (error: any) {
    yield put(licenseLoadFailure(uiHelper.errorMessage(error)))
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  }
}

export function* licenseSaveSaga(action: any): Generator<any, any, any> {
  try {
    const { data }: any = yield axios.patch(uris.license, action.payload)
    yield put(licenseSaveSuccess(data))
    yield put(
      addNotification(
        uiConstants.messages.license_saved_successfully,
        uiConstants.notification.success
      )
    )
  } catch (error: any) {
    yield put(licenseSaveFailure(uiHelper.errorMessage(error)))
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  }
}
