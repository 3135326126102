import { authConstants } from '../constants'

export const loginGoogle = () => {
  return {
    type: authConstants.AUTH_LOGIN_GOOGLE
  }
}

export const loginGithub = () => {
  return {
    type: authConstants.AUTH_LOGIN_GITHUB
  }
}

export const loginMicrosoft = () => {
  return {
    type: authConstants.AUTH_LOGIN_GITHUB
  }
}

export const loginSuccess = () => {
  return {
    type: authConstants.AUTH_LOGIN_SUCCESS
  }
}

export const loginFailure = (payload: string) => {
  return {
    type: authConstants.AUTH_LOGIN_FAILURE,
    payload
  }
}

export const validateAuth = () => {
  return {
    type: authConstants.AUTH_VALIDATE
  }
}

export const validateAuthSuccess = () => {
  return {
    type: authConstants.AUTH_VALIDATE_SUCCESS
  }
}

export const validateAuthFailure = () => {
  return {
    type: authConstants.AUTH_VALIDATE_FAILURE
  }
}

export const validateAuthSetMe = (payload: any) => {
  return {
    type: authConstants.AUTH_VALIDATE_SET_ME,
    payload
  }
}

export const logoutUser = () => {
  return {
    type: authConstants.AUTH_LOGOUT
  }
}

export const logoutUserSuccess = () => {
  return {
    type: authConstants.AUTH_LOGOUT_SUCCESS
  }
}

export const logoutUserFailure = () => {
  return {
    type: authConstants.AUTH_LOGOUT_FAILURE
  }
}
