import { visitConstants } from '../constants'

export const visitLoad = (payload: any) => {
  return {
    type: visitConstants.VISIT_LOAD,
    payload
  }
}

export const visitLoadSuccess = (payload: any) => {
  return {
    type: visitConstants.VISIT_LOAD_SUCCESS,
    payload
  }
}

export const visitLoadFailure = (payload: Error) => {
  return {
    type: visitConstants.VISIT_LOAD_FAILURE,
    payload
  }
}

export const visitLoadDetail = (payload: any) => {
  return {
    type: visitConstants.VISIT_LOAD_DETAIL,
    payload
  }
}

export const visitLoadDetailSuccess = (payload: any) => {
  return {
    type: visitConstants.VISIT_LOAD_DETAIL_SUCCESS,
    payload
  }
}

export const visitLoadDetailFailure = (payload: Error) => {
  return {
    type: visitConstants.VISIT_LOAD_DETAIL_FAILURE,
    payload
  }
}

export const visitReset = () => {
  return {
    type: visitConstants.VISIT_RESET
  }
}
