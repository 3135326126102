import { visitConstants } from '../constants'
import { timeHelper } from '../../helpers'

const initialState = {
  loading: false,
  error: null,
  list: null,
  current: null,
  startDate: timeHelper.formatEpoch(timeHelper.daysAgo(120), 'YYYY-MM-DD'),
  endDate: timeHelper.formatEpoch(timeHelper.now(), 'YYYY-MM-DD')
}

export default function visit(state = initialState, action: any) {
  switch (action.type) {
    case visitConstants.VISIT_LOAD:
      return {
        ...state,
        loading: true,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate
      }
    case visitConstants.VISIT_LOAD_DETAIL:
      return {
        ...state,
        loading: true
      }
    case visitConstants.VISIT_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.list
      }
    case visitConstants.VISIT_LOAD_FAILURE:
    case visitConstants.VISIT_LOAD_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case visitConstants.VISIT_LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        current: action.payload
      }
    case visitConstants.VISIT_RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
