import { licenseConstants } from '../constants'

const initialState = {
  loading: false,
  init: false,
  error: null,
  list: null
}

export default function license(state = initialState, action: any) {
  switch (action.type) {
    case licenseConstants.LICENSE_LOAD:
      return {
        ...state,
        loading: true,
        init: true
      }
    case licenseConstants.LICENSE_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.list
      }
    case licenseConstants.LICENSE_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case licenseConstants.SET_LICENSE:
      return {
        ...state,
        current: action.payload
      }
    case licenseConstants.LICENSE_SAVE:
      return {
        ...state,
        loading: true
      }
    case licenseConstants.LICENSE_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: (state.list || []).map((x: any) => {
          return x._id === action.payload._id ? action.payload : x
        }),
        current: action.payload
      }
    case licenseConstants.LICENSE_SAVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case licenseConstants.LICENSE_RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
