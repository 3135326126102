export const uiConstants = {
  messages: {
    network_error: 'Network error',
    apikey_copied_to_clipboard: 'Api key copied to clipboard',
    license_saved_successfully: 'License saved successfully'
  },
  notification: {
    info: 'Info',
    error: 'Error',
    warning: 'Warning',
    success: 'Success'
  },
  localStorage: {
    license: 'license'
  },
  login: [
    {
      label: 'Username',
      name: 'username',
      type: 'text',
      placeholder: 'Username',
      required: true
    },
    {
      label: 'Password',
      name: 'password',
      type: 'password',
      placeholder: 'Password',
      required: true
    }
  ],
  menu: [
    {
      icon: 'fa-solid fa-gauge-high',
      to: '/dashboard'
    },
    {
      icon: 'fa-solid fa-computer',
      to: '/devices'
    },
    {
      icon: 'fa-solid fa-gears',
      to: '/settings'
    }
  ],
  license: [
    {
      icon: 'fa-solid fa-key',
      label: 'Copy Api Key',
      click: 'handleCopyApiKey'
    },
    {
      icon: 'fa-solid fa-rotate',
      label: 'Refresh',
      click: 'handleRefreshVisit',
      active: true
    },
    {
      icon: 'fa-solid fa-sliders',
      label: 'FIlter',
      click: 'handleTimeRange'
    },
    {
      icon: 'fa-solid fa-swatchbook',
      label: 'Change License',
      click: 'setShowLicenses'
    }
  ],
  nivoColors: {
    nivo: 'nivo',
    category10: 'category10',
    accent: 'accent',
    dark2: 'dark2',
    paired: 'paired',
    pastel1: 'pastel1',
    pastel2: 'pastel2',
    set1: 'set1',
    set2: 'set2',
    set3: 'set3',
    tableau10: 'tableau10',
    brown_blueGreen: 'brown_blueGreen',
    purpleRed_green: 'purpleRed_green',
    pink_yellowGreen: 'pink_yellowGreen',
    purple_orange: 'purple_orange',
    red_blue: 'red_blue',
    red_grey: 'red_grey',
    red_yellow_blue: 'red_yellow_blue',
    red_yellow_green: 'red_yellow_green',
    spectral: 'spectral',
    blues: 'blues',
    greens: 'greens',
    greys: 'greys',
    oranges: 'oranges',
    purples: 'purples',
    reds: 'reds',
    blue_green: 'blue_green',
    blue_purple: 'blue_purple',
    green_blue: 'green_blue',
    orange_red: 'orange_red',
    purple_blue_green: 'purple_blue_green',
    purple_blue: 'purple_blue',
    purple_red: 'purple_red',
    red_purple: 'red_purple',
    yellow_green_blue: 'yellow_green_blue',
    yellow_green: 'yellow_green',
    yellow_orange_brown: 'yellow_orange_brown',
    yellow_orange_red: 'yellow_orange_red'
  },
  daysWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  settings: [
    {
      label: 'License name',
      name: 'name',
      type: 'text',
      placeholder: 'License name',
      required: true
    },
    {
      label: 'Origin',
      name: 'origin',
      type: 'url',
      placeholder: 'Origin',
      required: true
    },
    {
      label: 'Use cookie',
      name: 'useCookie',
      type: 'checkbox',
      placeholder:
        'If you set this to true, the visitorId will be stored in a cookie.',
      required: false
    }
  ],
  timeRange: [
    {
      label: 'Start data',
      name: 'startDate',
      type: 'date',
      placeholder: 'Start date',
      required: true
    },
    {
      label: 'End date',
      name: 'endDate',
      type: 'date',
      placeholder: 'End date',
      required: true
    }
  ]
}
