import { combineReducers } from 'redux'

import auth from './auth.reducer'
import redirect from './redirect.reducer'
import notify from './notify.reducer'
import license from './license.reducer'
import visit from './visit.reducer'

const rootReducer = combineReducers({
  auth,
  redirect,
  notify,
  license,
  visit
})

export default rootReducer
