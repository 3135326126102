export const licenseConstants = {
  LICENSE_LOAD: '[License] Load',
  LICENSE_LOAD_SUCCESS: '[License] Load success',
  LICENSE_LOAD_FAILURE: '[License] Load failure',
  LICENSE_RESET: '[License] Reset',
  SET_LICENSE: '[License] Set',
  LICENSE_SAVE: '[License] Save',
  LICENSE_SAVE_SUCCESS: '[License] Save success',
  LICENSE_SAVE_FAILURE: '[License] Save failure'
}
