import { takeEvery } from 'redux-saga/effects'
import { authConstants, licenseConstants, visitConstants } from '../constants'
import {
  loginGithubSaga,
  loginGoogleSaga,
  loginMicrosoftSaga,
  validateAuthSaga,
  logoutUserSaga
} from './auth.sagas'
import { licenseLoadSaga, licenseSaveSaga } from './license.sagas'
import { visitLoadSaga, visitLoadDetailSaga } from './visit.sagas'

export function* watchAuth() {
  yield takeEvery(authConstants.AUTH_LOGIN_GOOGLE, loginGoogleSaga)
  yield takeEvery(authConstants.AUTH_LOGIN_GITHUB, loginGithubSaga)
  yield takeEvery(authConstants.AUTH_LOGIN_MICROSOFT, loginMicrosoftSaga)
  yield takeEvery(authConstants.AUTH_VALIDATE, validateAuthSaga)
  yield takeEvery(authConstants.AUTH_LOGOUT, logoutUserSaga)
}

export function* watchLicense() {
  yield takeEvery(licenseConstants.LICENSE_LOAD, licenseLoadSaga)
  yield takeEvery(licenseConstants.LICENSE_SAVE, licenseSaveSaga)
}

export function* watchVisit() {
  yield takeEvery(visitConstants.VISIT_LOAD, visitLoadSaga)
  yield takeEvery(visitConstants.VISIT_LOAD_DETAIL, visitLoadDetailSaga)
}
