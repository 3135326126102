import { put } from 'redux-saga/effects'
import axios from '../../axios-conf'
import uris from '../../uris'
import {
  visitLoadSuccess,
  visitLoadFailure,
  addNotification,
  visitLoadDetailSuccess,
  visitLoadDetailFailure
} from '../actions'
import { uiHelper } from '../../helpers'
import { uiConstants } from '../../constants'

export function* visitLoadSaga(action: any): Generator<any, any, any> {
  try {
    const { license, startDate, endDate } = action.payload

    const { data }: any = yield axios.get(
      `${uris.visit}/${license}?startDate=${startDate}&endDate=${endDate}`
    )
    yield put(visitLoadSuccess(data))
  } catch (error: any) {
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
    yield put(visitLoadFailure(uiHelper.errorMessage(error)))
  }
}

export function* visitLoadDetailSaga(action: any): Generator<any, any, any> {
  try {
    const { data }: any = yield axios.get(
      `${uris.visitDetail}/${action.payload}`
    )
    yield put(visitLoadDetailSuccess(data))
  } catch (error: any) {
    yield put(visitLoadDetailFailure(uiHelper.errorMessage(error)))
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  }
}
