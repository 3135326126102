import moment from 'moment'

const now = () => {
  return moment().utc().unix()
}

const formatEpoch = (epoch: number, format = 'YYYY-MM-DD HH:mm:ss') => {
  return moment.unix(epoch).format(format)
}

const daysAgo = (days: number) => {
  return moment()
    .utc()
    .add(days * -1, 'days')
    .startOf('day')
    .unix()
}

const dateToEpoch = (date: string) => {
  return moment(date).utc().unix()
}

export const timeHelper = {
  now,
  formatEpoch,
  daysAgo,
  dateToEpoch
}
