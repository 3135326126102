import { authConstants } from '../constants'

const initialState = {
  error: null,
  loading: false,
  validating: false,
  me: null
}

export default function auth(state = initialState, action: any) {
  switch (action.type) {
    case authConstants.AUTH_LOGIN_GOOGLE:
    case authConstants.AUTH_LOGIN_GITHUB:
    case authConstants.AUTH_LOGIN_MICROSOFT:
      return { ...state, loading: true, error: null, validating: false }
    case authConstants.AUTH_LOGIN_SUCCESS:
      return { ...state, error: null, loading: false }
    case authConstants.AUTH_LOGIN_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case authConstants.AUTH_VALIDATE:
      return { ...state, loading: true, validating: true, error: null }
    case authConstants.AUTH_VALIDATE_SUCCESS:
      return { ...state, loading: false }
    case authConstants.AUTH_VALIDATE_SET_ME:
      return { ...state, me: action.payload }
    case authConstants.AUTH_VALIDATE_FAILURE:
      return { ...state, loading: false, me: null }

    case authConstants.AUTH_LOGOUT:
      return {
        ...state,
        loading: true,
        error: null,
        me: null,
        validating: false
      }
    case authConstants.AUTH_LOGOUT_SUCCESS:
      return { ...state, loading: false, error: null }
    // TODO: logout failure
    default:
      return state
  }
}
