import { licenseConstants } from '../constants'

export const licenseLoad = () => {
  return {
    type: licenseConstants.LICENSE_LOAD
  }
}

export const licenseLoadSuccess = (payload: any) => {
  return {
    type: licenseConstants.LICENSE_LOAD_SUCCESS,
    payload
  }
}

export const licenseLoadFailure = (payload: Error) => {
  return {
    type: licenseConstants.LICENSE_LOAD_FAILURE,
    payload
  }
}

export const licenseReset = () => {
  return {
    type: licenseConstants.LICENSE_RESET
  }
}

export const setLicense = (payload: any) => {
  return {
    type: licenseConstants.SET_LICENSE,
    payload
  }
}

export const licenseSave = (payload: any) => {
  return {
    type: licenseConstants.LICENSE_SAVE,
    payload
  }
}

export const licenseSaveSuccess = (payload: any) => {
  return {
    type: licenseConstants.LICENSE_SAVE_SUCCESS,
    payload
  }
}

export const licenseSaveFailure = (payload: Error) => {
  return {
    type: licenseConstants.LICENSE_SAVE_FAILURE,
    payload
  }
}
