const uris = {
  apiBase: process.env.REACT_APP_API_BASE,
  auth: '/auth',
  dashboard: '/dashboard',
  license: '/license',
  visit: '/visit',
  visitDetail: '/visit/detail'
}

export default uris
