const errorMessage = (error: any) => {
  try {
    const e = error.response?.data?.message ?? error.message ?? error
    if (!e) {
      throw new Error('Generic Error')
    }
    return e
  } catch {
    return 'Generic Error'
  }
}

const formInitialValues = (fields: any, initialValues: any, setValue: any) => {
  fields.forEach((f: any) => {
    if (f.ignoreOnEdit || initialValues[f.name] === '') return
    setValue(f.name, initialValues[f.name] || '')
  })
}

export const uiHelper = {
  errorMessage,
  formInitialValues
}
